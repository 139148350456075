/* eslint-disable jsx-a11y/anchor-is-valid */

import useViews from '../hooks/useViews';
import { currentSlideAtom, showAvailableState } from '../states/planner';
import { useAtom } from 'jotai';

const AreaView = ({
  id,
  d,
  company,
  siteid,
  toolTipPlace,
  toolTipId,
}: {
  id: string;
  d: string;
  company: string;
  siteid: string;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const [views] = useViews(company, siteid);
  const [showAvailable] = useAtom(showAvailableState);
  const [, setCurrentSlide] = useAtom(currentSlideAtom); // Track current slide

  const changeSlide = () => {
    setCurrentSlide((id as unknown as number) / 1);
  };

  const view = views[+id];
  const setDataToolTip = toolTipId
    ? {
        'data-tooltip-id': toolTipId,
        'data-tooltip-picture': view.daypicture,
        'data-tooltip-direction': view.compass,
        'data-tooltip-content': 'dsadsad',
        'data-tooltip-place': toolTipPlace,
      }
    : {};

  const classNames = ['view-path', showAvailable ? 'show' : ''];

  return (
    <a
      id={'AreaView-' + id}
      href=""
      onClick={e => {
        e.preventDefault();
        changeSlide();
      }}
      className={'relative block'}
    >
      <path id={id.toString()} className={classNames.join(' ')} d={d} {...setDataToolTip} />
    </a>
  );
};

export default AreaView;
