import radioButton from '../assets/radiobutton.svg';
import radioButtonChecked from '../assets/radiobutton_checked.svg';

const RadioButton = ({ checked }: { checked: boolean }) => {
  if (checked) {
    return <img src={radioButtonChecked} className="ml-3 h-5" alt="radio" />;
  }

  return <img src={radioButton} className="ml-3 h-5" alt="radio" />;
};

export default RadioButton;
