import { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useAtom } from 'jotai';
import { avalableROKInApartments, showROKState, userData, showAvailableState } from '../states/planner';
import { matchRoutes, useLocation } from 'react-router-dom';
import { isSignInWithEmailLink, onAuthStateChanged, signInWithEmailLink, signOut } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { Tooltip } from 'react-tooltip';
import RadioButton from '../components/RadioButton';

// Top navbar
export default function NavBar() {
  const [user, setUser] = useAtom(userData);

  const [showAvailable, setShowAvailable] = useAtom(showAvailableState);
  const [availableROK] = useAtom(avalableROKInApartments);

  const [showFilter, setShowFilter] = useState(false);
  const [rokFilter, setRokFilter] = useAtom(showROKState);

  //const { bostad } = useHistorey

  const location = useLocation();
  const routes = [{ path: '/:company/admin/*' }];

  const route = matchRoutes(routes, location);
  const routeParam = route ? route[0].params.company : '';
  const isAdminPage = routeParam;

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user && user.uid) {
        setUser({ id: user.uid });
      } else {
        setUser({ id: 'anonymous' });
      }
    });
  }, []);

  if (user && user.id === 'anonymous') {
    // User is null if useEffect have not run
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
            // setUser({ id: result.user.uid });
          })
          .catch((error: FirebaseError) => {
            throw new Error(error.message);
          });
      }
    }
  }
  function toggleShowAvailable(set?: boolean) {
    setShowAvailable(set || !showAvailable);
  }

  function toggleROK(rok: number) {
    const newRokFilter = [...rokFilter];
    newRokFilter[rok] = !newRokFilter[rok];
    toggleShowAvailable(true);
    setRokFilter(newRokFilter);
  }

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser({ id: 'anonymous' });
      })
      .catch((error: FirebaseError) => {
        console.log(error);
        // An error happened.
      });
  };

  if (isAdminPage) {
    return (
      <header className="mb-2 mt-2">
        <nav>
          <ul className="flex flex-wrap justify-between">
            <li className="ml-3 mr-5 text-base font-extrabold">ADMIN PAGE</li>
            {user && user.id !== 'anonymous' && (
              <li className="mr-2 flex items-center text-xs md:mr-5 md:text-sm">
                <div className="flex justify-end">
                  <button onClick={() => handleSignOut()}>Sign out</button>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </header>
    );
  }

  return (
    <header className="mb-0 mt-0 sm:mb-2 sm:mt-2">
      <nav>
        <div className="flex flex-col justify-between sm:flex-row">
          <ul className="mr-7 mt-2 flex list-none p-0 sm:mt-0">
            <li className="font-style-one ml-3 mr-2 font-bold sm:mr-5">Bostadsrätter</li>
            <li className="mr-2 flex items-center text-xs">
              <svg width="16" height="16">
                <circle cx="8" cy="8" r="3" fill="#18974d" />
              </svg>
              <span className="whitespace-nowrap">Till salu</span>
            </li>
            <li className="mr-2 flex items-center text-xs">
              <svg width="16" height="16">
                <circle cx="8" cy="8" r="3" fill="orange" />
              </svg>
              Bokad
            </li>
            <li className="mr-2 flex items-center text-xs">
              <svg width="16" height="16">
                <circle cx="8" cy="8" r="3" fill="grey" />
              </svg>
              Såld
            </li>
          </ul>

          {/* <ul className="ml-3 flex list-none flex-wrap justify-between"> */}
          <ul className="ml-3 mr-3 flex flex-grow list-none border-t border-t-black p-0 sm:border-t-0">
            <li className="ml-auto mr-3 font-bold">
              <button
                className="font-style-one mr-3 flex items-center whitespace-nowrap"
                onClick={() => toggleShowAvailable()}
              >
                Visa lägenheter till salu
                <RadioButton checked={showAvailable} />
              </button>
            </li>

            <Tooltip
              id="filterBox"
              openOnClick
              clickable
              afterShow={() => setShowFilter(true)}
              afterHide={() => setShowFilter(false)}
              className="z-10 -ml-1 mt-2 bg-white"
              noArrow
              place="bottom-end"
              style={{
                backgroundColor: 'white',
                color: 'black',
                visibility: showFilter ? 'visible' : 'hidden',
              }}
            >
              <li className="ml-auto font-bold">
                <ul>
                  {rokFilter.map((rok, index) => {
                    const roomsAvailable = availableROK[index];

                    if (!roomsAvailable) return '';

                    return (
                      <li key={`rok-${index}`} className="mb-2">
                        <button className="font-style-one flex items-center font-bold" onClick={() => toggleROK(index)}>
                          {index + 1} rok
                          <RadioButton checked={rokFilter[index]} />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </Tooltip>
            <li className="">
              <button
                className="font-style-one ml-auto font-bold"
                data-tooltip-id="filterBox"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filtrera
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
