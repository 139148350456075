import { useEffect, useState } from 'react';

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import BuildingView from './BuildingView';
import useViews from '../hooks/useViews';
import arrowLeft from '../assets/arrow_left.svg';
import arrowRight from '../assets/arrow_right.svg';
import Compass from './Compass';
import logo from '../assets/ps_symbol.svg';
import { Tooltip } from 'react-tooltip';
import TooltipApartment from './TooltipApartment';
import TooltipView from './TooltipView';
import TooltipInfo from './TooltipInfo';
import PintarStudioLogo from './PintarStudioLogo';
import { currentSlideAtom } from '../states/planner';
import { useAtom } from 'jotai';

const CarouselBuildingViews = ({
  company,
  siteId,
  overviewPicture,
}: {
  company: string;
  siteId: string;
  overviewPicture: string;
}) => {
  const [siteViews] = useViews(company, siteId);
  const [aspect, setAspect] = useState([0, 0]);
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom); // Track current slide

  const isMobile = () => {
    return window.matchMedia('(max-width: 767px)').matches;
  };

  useEffect(() => {
    if (siteViews.length > 0) {
      const img = new Image();
      img.onload = () => {
        setAspect([img.width, img.height]);
        setLoaded(true);
      };

      img.src = siteViews[0].daypicture;
    }
  }, [siteViews]);

  if (!loaded) {
    return (
      <section className="sec-loading">
        <div className="one">
          <img className="bottom-10 h-32 w-32" alt="logo" src={logo}></img>
        </div>
      </section>
    );
  }

  const isMobileDevice = isMobile();
  const totalSlides = siteViews.length;

  return (
    <CarouselProvider
      naturalSlideWidth={aspect[0]}
      naturalSlideHeight={aspect[1]}
      totalSlides={totalSlides}
      currentSlide={currentSlide} // Control the current slide
      className="relative select-none pb-12"
      isPlaying={false} // Disable the animation (auto-play and transition)
      infinite={false}
      // dragEnabled={false}
      // touchEnabled={false}
    >
      <Slider>
        {siteViews.map((view, index) => (
          <Slide key={view.viewid} index={index}>
            <BuildingView
              company={company}
              siteid={siteId}
              viewid={view.viewid}
              svgsize={view.svgsize}
              picture={view.daypicture}
              compass={view.compass}
            />
          </Slide>
        ))}
      </Slider>

      <Tooltip
        className="tooltipmedia"
        id={'apartment-tooltip'}
        render={TooltipApartment}
        closeEvents={{ click: true, mouseleave: true, blur: true }}
        style={{
          backgroundColor: 'white',
          color: 'black',
          paddingLeft: '0px',
          borderRadius: '8px',
          zIndex: 10,
        }}
      />
      <Tooltip
        className="tooltipmedia"
        id={'view-tooltip'}
        render={TooltipView}
        closeEvents={{ click: true, mouseleave: true, blur: true }}
        opacity={0.95}
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '0px',
          borderRadius: '8px',
          zIndex: 10,
        }}
      />
      <Tooltip
        className=""
        id={'info-tooltip'}
        render={TooltipInfo}
        closeEvents={{
          click: isMobileDevice,
          mouseleave: !isMobileDevice,
          blur: !isMobileDevice,
        }}
        clickable={isMobileDevice}
        openOnClick={isMobileDevice}
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '0px',
          borderRadius: '8px',
          zIndex: 10,
        }}
      />
      <PintarStudioLogo />

      <Compass siteViews={siteViews} overviewPicture={overviewPicture} />

      <div className="absolute left-[calc(50%-100px)] flex h-12 -translate-x-1/2 transform items-center justify-center">
        <button
          className="transform"
          onClick={() => setCurrentSlide(prev => Math.max(prev - 1, 0))} // Go to the previous slide
          disabled={currentSlide === 0} // Disable if first slide
        >
          <img
            src={arrowLeft}
            alt="arrow left"
            className={`h-8 w-8 ${currentSlide === 0 ? 'opacity-50' : 'hover:h-9 hover:w-9'}`}
          />
        </button>
      </div>

      <div className="absolute left-[calc(50%+100px)] flex h-12 -translate-x-1/2 transform items-center justify-center">
        <button
          className="transform"
          onClick={() => setCurrentSlide(prev => Math.min(prev + 1, totalSlides - 1))} // Go to the next slide
          disabled={currentSlide === totalSlides - 1} // Disable if last slide
        >
          <img
            src={arrowRight}
            alt="arrow right"
            className={`h-8 w-8 ${currentSlide === totalSlides - 1 ? 'opacity-50' : 'hover:h-9 hover:w-9'}`}
          />
        </button>
      </div>

      <div id="modal" className="view-border invisible absolute left-0 top-0 z-10 h-full w-full align-middle"></div>
    </CarouselProvider>
  );
};

export default CarouselBuildingViews;
