import { useParams } from 'react-router-dom';
import useSite from '../hooks/useSite';
import { useApartments } from '../hooks/useApartments';
import CarouselBuildingViews from '../components/CarouselBuildingViews';
import TableApartments from '../components/TableApartments';
import { apartmentFilter } from '../states/planner';
import { useAtom } from 'jotai';

const Planner = () => {
  const { company, sitename } = useParams();

  if (!company) {
    throw new Error('no company provided to details');
  }

  if (!sitename) {
    throw new Error('no site provided to details');
  }

  const [site] = useSite(company, sitename);
  const siteId = site?.siteid;
  const overviewPicture = site?.overviewpicture;

  useApartments(company, siteId);
  const [filteredApartments] = useAtom(apartmentFilter);

  if (!siteId) {
    // captureMessage(
    // "Site does not exist:" + company + "/" + sitename,
    // "warning",
    // );
    return <></>;
  }

  return (
    <div className="planner-wrapper relative">
      <CarouselBuildingViews company={company} siteId={siteId} overviewPicture={overviewPicture} />
      <TableApartments apartments={filteredApartments} />
    </div>
  );
};

export default Planner;
