import camera from '../assets/camera.svg';
import cameraChecked from '../assets/camera_checked.svg';
import { useAtom } from 'jotai';
import { currentSlideAtom } from '../states/planner';

const CompassCamera = ({ degrees, slide }: { degrees: number; slide: number }) => {
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);

  if (slide < 0) return <div></div>;

  const changeSlide = () => {
    if (slide !== currentSlide) {
      setCurrentSlide(slide);
    }
  };

  const cameraURL = slide === currentSlide ? cameraChecked : camera;
  return (
    <button onClick={() => changeSlide()}>
      <img alt="camera" src={cameraURL} className="h-6 w-6" style={{ transform: `rotate(${degrees}deg)` }}></img>
    </button>
  );
};

export default CompassCamera;
