import { View } from '../CommonTypes';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';

const fetchViews = async (company: string, siteId: string) => {
  if (process.env.NODE_ENV === 'development') console.log('fetching views', siteId, company);

  const colRef = query(collection(db, `${company}/${siteId}/views`), orderBy('order'));

  const results = await getDocs(colRef);

  return results.docs.map(doc => {
    const data = doc.data() as Omit<View, 'viewid'>;
    return { ...data, viewid: doc.id } as View;
  });
};

export default fetchViews;
