import { QueryStatus, useQuery } from '@tanstack/react-query';

import fetchViews from './fetchViews';
import { View } from '../CommonTypes';

export default function useViews(company: string, siteId: string) {
  const results = useQuery({
    queryKey: ['company', company, 'siteId', siteId],
    queryFn: () => fetchViews(company, siteId),
  });

  return [results?.data ?? [], results.status] as [View[], QueryStatus];
}
