// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDoalRxE1BYoeHVS8CRhlw-DRwt9hdjalc',
  authDomain: 'bostadsplanerare.firebaseapp.com',
  projectId: 'bostadsplanerare',
  storageBucket: 'bostadsplanerare.appspot.com',
  messagingSenderId: '278881924775',
  appId: '1:278881924775:web:0e267bbcccebc19952cd82',
};

const cloudFlareBucketURL = 'https://bostad-storage.pintar.se/';

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();

export function storageUrl(value: string) {
  if (value.startsWith('https://firebasestorage.googleapis.com')) {
    return value;
  } else if (value.startsWith('gs://')) {
    return value.replace('gs://', 'https://firebasestorage.googleapis.com/v0/b/bostadsplanerare.appspot.com/o/');
  }

  return cloudFlareBucketURL + value;
}
