import { ApartmentStatus } from '../CommonTypes';

const StatusCell = ({ status, onChange }: { status: string; onChange?: (value: ApartmentStatus) => void }) => {
  const validateStatus = (status: string) => {
    switch (status) {
      case 'available':
        return 'available';
      case 'sold':
        return 'sold';
      case 'reserved':
        return 'reserved';
      default:
        throw new Error('Invalid status');
    }
  };

  if (onChange) {
    return (
      <select
        name="status"
        id="status"
        className="h-8 w-[65px] appearance-none rounded-md border border-gray-300 bg-right bg-no-repeat pl-1 pr-0 text-xs md:w-36"
        onChange={e => onChange(validateStatus(e.target.value))}
        defaultValue={status}
      >
        <option value="available">Till Salu</option>
        <option value="sold">Såld</option>
        <option value="reserved">Bokad</option>
      </select>
    );
  }

  switch (status) {
    case 'available':
      return (
        <span className="-ml-1 flex items-center">
          <svg width="16" height="16">
            <circle cx="8" cy="9" r="3" fill="green" />
          </svg>
          <span className="whitespace-nowrap">Till salu</span>
        </span>
      );
    case 'sold':
      return (
        <span className="-ml-1 flex items-center">
          <svg width="16" height="16">
            <circle cx="8" cy="9" r="3" fill="gray" />
          </svg>
          <span className="whitespace-nowrap">Såld</span>
        </span>
      );
    case 'reserved':
      return (
        <span className="-ml-1 flex items-center">
          <svg width="16" height="16">
            <circle cx="8" cy="9" r="3" fill="orange" />
          </svg>
          <span className="whitespace-nowrap">Bokad</span>
        </span>
      );
    default:
      return <span>{status}</span>;
  }
};

export default StatusCell;
