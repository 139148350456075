import useLocations from '../hooks/useLocations';
import { storageUrl } from '../firebase';
import AreaApartment from './AreaApartment';
import AreaView from './AreaView';
import AreaInfo from './AreaInfo';
import { useInfos } from '../hooks/useInfos';

interface BuildingViewArgs {
  company: string;
  siteid: string;
  viewid: string;
  svgsize: string;
  picture: string;
  compass:
    | 'N'
    | 'NNW'
    | 'NW'
    | 'WNW'
    | 'W'
    | 'WSW'
    | 'SW'
    | 'SSW'
    | 'S'
    | 'SSE'
    | 'SE'
    | 'ESE'
    | 'E'
    | 'ENE'
    | 'NE'
    | 'NNE';
}

const BuildingView = ({ company, siteid, viewid, picture, svgsize, compass }: BuildingViewArgs) => {
  const [locations] = useLocations(company, siteid, viewid);
  const [infos] = useInfos(company, siteid);

  return (
    <div className="relative inline-block">
      <img
        alt="ViewImage"
        src={storageUrl(picture)}
        className={'background-img ' + compass + ' block h-auto max-w-full'}
      />
      <svg className="absolute left-0 top-0" viewBox={`0 0 ${svgsize}`} preserveAspectRatio="none">
        {locations.map(l => {
          if (l.type === 'apartment') {
            return (
              <AreaApartment
                key={l.id}
                apartmentId={l.ref_id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={'apartment-tooltip'}
              />
            );
          } else if (l.type === 'view') {
            return (
              <AreaView
                company={company}
                siteid={siteid}
                key={l.id}
                id={l.ref_id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={'view-tooltip'}
              />
            );
          } else if (l.type === 'info') {
            const info = infos.find(i => i.id === l.ref_id);
            if (!info) return undefined;

            return (
              <AreaInfo
                info={info}
                key={l.id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={'info-tooltip'}
              />
            );
          }
        })}
      </svg>
    </div>
  );
};

export default BuildingView;
