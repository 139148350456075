import { useContext, useEffect, useState } from 'react';
import compassUrl from '../assets/compass.svg';
import { CarouselContext } from 'pure-react-carousel';
import CompassCamera from './CompassCamera';
import { View } from '../CommonTypes';
import { storageUrl } from '../firebase';
import { currentSlideAtom } from '../states/planner';
import { useAtom } from 'jotai';
const Compass = ({ siteViews, overviewPicture }: { siteViews: View[]; overviewPicture: string }) => {
  const [showViewSelector, setShowViewSelector] = useState(false);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);

  const carouselContext = useContext(CarouselContext);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const directions = siteViews.map(view => view.compass);

  const translateCompass = (compass: string) => {
    let degrees = 0;

    switch (compass) {
      case 'N':
        degrees = 180;
        break;
      case 'NNE':
        degrees = 157.5;
        break;
      case 'NE':
        degrees = 135;
        break;
      case 'ENE':
        degrees = 112.5;
        break;
      case 'E':
        degrees = 90;
        break;
      case 'ESE':
        degrees = 67.5;
        break;
      case 'SE':
        degrees = 45;
        break;
      case 'SSE':
        degrees = 22.5;
        break;
      case 'S':
        degrees = 0;
        break;
      case 'SSW':
        degrees = 337.5;
        break;
      case 'SW':
        degrees = 315;
        break;
      case 'WSW':
        degrees = 292.5;
        break;
      case 'W':
        degrees = 270;
        break;
      case 'WNW':
        degrees = 247.5;
        break;
      case 'NW':
        degrees = 225;
        break;
      case 'NNW':
        degrees = 202.5;
        break;
      default:
        degrees = 180;
        break;
    }

    return `rotate(${degrees}deg)`;
  };

  return (
    <>
      <div
        className="absolute left-0 top-0 h-full w-full cursor-default"
        role="button"
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && setShowViewSelector(false)}
        style={{ visibility: showViewSelector ? 'visible' : 'hidden' }}
        onClick={() => setShowViewSelector(false)}
      ></div>
      <div
        className={`absolute -bottom-2 left-1/2 z-10 h-52 w-52 rounded-md p-3 opacity-90 min-[460px]:h-64 min-[460px]:w-64 sm:-bottom-16 sm:h-96 sm:w-96 ${
          showViewSelector ? 'visible scale-100 opacity-100' : 'invisible scale-90 opacity-0'
        }`}
        id="viewSelector"
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(${storageUrl(overviewPicture)})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex justify-between">
            <CompassCamera degrees={45} slide={directions.indexOf('NW')} />
            <CompassCamera degrees={67.5} slide={directions.indexOf('NNW')} />
            <CompassCamera degrees={90} slide={directions.indexOf('N')} />
            <CompassCamera degrees={112.5} slide={directions.indexOf('NNE')} />
            <CompassCamera degrees={135} slide={directions.indexOf('NE')} />
          </div>
          <div className="middle-row flex justify-between">
            <div className="flex items-center">
              <CompassCamera degrees={22.5} slide={directions.indexOf('WNW')} />
            </div>
            <div></div>
            <div className="flex items-center">
              <CompassCamera degrees={157.5} slide={directions.indexOf('ENE')} />
            </div>
          </div>
          <div className="middle-row flex justify-between">
            <div className="flex items-center">
              <CompassCamera degrees={0} slide={directions.indexOf('W')} />
            </div>
            <div></div>
            <div className="flex items-center">
              <CompassCamera degrees={180} slide={directions.indexOf('E')} />
            </div>
          </div>
          <div className="middle-row flex justify-between">
            <div className="flex items-center">
              <CompassCamera degrees={337.5} slide={directions.indexOf('WSW')} />
            </div>
            <div></div>
            <div className="flex items-center">
              <CompassCamera degrees={202.5} slide={directions.indexOf('ESE')} />
            </div>
          </div>
          <div className="flex justify-between">
            <CompassCamera degrees={315} slide={directions.indexOf('SW')} />
            <CompassCamera degrees={337.5} slide={directions.indexOf('SSW')} />
            <CompassCamera degrees={270} slide={directions.indexOf('S')} />
            <CompassCamera degrees={247.5} slide={directions.indexOf('SSE')} />
            <CompassCamera degrees={225} slide={directions.indexOf('SE')} />
          </div>
        </div>
      </div>
      <button
        data-tooltip-id="viewSelector"
        data-tooltip-place="top"
        onClick={() => setShowViewSelector(!showViewSelector)}
        style={{
          transform: `translate(-50%, -50%) ${translateCompass(directions[currentSlide])}`,
        }}
        className="compass z-1 absolute left-1/2 h-20 w-20 transition-transform duration-500 ease-out hover:h-[82px] hover:w-[82px]"
      >
        <img src={compassUrl} alt={'compass'} />
      </button>
    </>
  );
};

export default Compass;
