import { useState } from 'react';

import logo from '../assets/ps_symbol.svg';

const PintarStudioLogo = () => {
  const [showViewSelector, setShowViewSelector] = useState(false);

  return (
    <>
      {/* Overlay to close the popup */}
      <div
        className={`absolute left-0 top-0 h-full w-full cursor-default ${showViewSelector ? 'visible' : 'invisible'}`}
        role="button"
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && setShowViewSelector(false)}
        onClick={() => setShowViewSelector(false)}
      ></div>

      {/* Popup dialog */}
      <div
        className={`absolute bottom-[90px] right-2 z-10 w-40 transform rounded-md bg-black p-3 text-xs text-white shadow-lg transition-all duration-300 ease-out md:bottom-[110px] ${
          showViewSelector ? 'visible scale-100 opacity-100' : 'invisible scale-90 opacity-0'
        }`}
        id="viewSelector"
      >
        <div className="flex flex-col gap-2">
          <p>Powered by Pintar Studios AB</p>
          <p>
            <a href="https://www.pintarstudios.se">www.pintarstudios.se</a>
          </p>
        </div>
      </div>

      {/* Button */}
      <button
        data-tooltip-id="viewSelector"
        data-tooltip-place="top"
        onClick={() => setShowViewSelector(!showViewSelector)}
        className={`absolute bottom-14 right-2 h-7 w-7 opacity-90 md:h-10 md:w-10 md:origin-center md:transform md:transition md:duration-100 md:hover:scale-110`}
      >
        <img className="h-full w-full" alt="logo" src={logo} />
      </button>
    </>
  );
};

export default PintarStudioLogo;
