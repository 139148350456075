import { useEffect, useState } from 'react';
import { storageUrl } from '../firebase';

interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const ToolTipImage = ({ urlin }: { urlin: string }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setUrl(img.src);
    };
    img.src = storageUrl(urlin);
  }, [urlin]);

  if (!url) return <></>;

  return <img src={storageUrl(url)} alt="floorplanpicture"></img>;
};

const TooltipApartment = ({ activeAnchor }: TArg) => {
  const status = activeAnchor?.getAttribute('data-tooltip-status');
  const rok = activeAnchor?.getAttribute('data-tooltip-rok');
  const price = parseInt(activeAnchor?.getAttribute('data-tooltip-price') || '');
  const charge = parseInt(activeAnchor?.getAttribute('data-tooltip-charge') || '');
  const size = activeAnchor?.getAttribute('data-tooltip-size');
  const apartmentid = activeAnchor?.getAttribute('data-tooltip-apartmentid');
  const floorplanpicture = activeAnchor?.getAttribute('data-tooltip-floorplanpicture') || '';

  const friendlyStatus = status === 'available' ? 'Till salu' : status === 'sold' ? 'Såld' : 'Bokad';

  const statusClass =
    status === 'available' ? 'status-available' : status === 'sold' ? 'status-sold' : 'status-pending';

  return (
    <div className="w-60 flex-col pb-2">
      <ul className="flex items-end">
        <li className="flex h-10 w-20 items-center">
          <svg viewBox="0 0 318.29 119.64" width={80} height={30} className="mt-3">
            <path
              className={statusClass}
              d="M0,0h258.42c33.11.37,59.86,27.58,59.87,59.87,0,31.17-24.94,57.82-56.84,59.78H0V0Z"
            />
          </svg>
          <div className="absolute pl-1 pt-3 font-bold text-white">{friendlyStatus}</div>
        </li>
        <li className="flex-grow"></li>
        <li>
          <div className="text-lg font-bold">
            {rok} rok, {size} m<sup>2</sup>
          </div>
          <div className="-mt-1 text-xs">Nr. {apartmentid}</div>
        </li>
      </ul>
      <div className="mt-4 align-top">
        <ToolTipImage urlin={floorplanpicture} />
      </div>
      <ul className="flex items-end pl-4">
        <li className="">
          <ul>
            <li className="mt-2 text-left font-bold">Pris</li>
            <li className="text-xs">{price > 0 ? price.toLocaleString('sv-SE') + ' kr/mån' : '-'}</li>
          </ul>
        </li>
        <li className="flex-grow"></li>
        <li className="">
          <ul>
            <li className="mt-2 text-left font-bold">Avgift</li>
            <li className="text-xs">{charge > 0 ? charge.toLocaleString('sv-SE') + ' kr/mån' : '-'}</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TooltipApartment;
