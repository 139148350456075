import { useState } from 'react';

const InputPriceCell = ({ price, onChange }: { price: number; onChange: (value: number) => void }) => {
  const [value, setValue] = useState(price);

  const validatePrice = (price: string) => {
    const priceAsNumber = parseInt(price);
    if (isNaN(priceAsNumber)) {
      return 0;
    }
    return priceAsNumber;
  };

  return (
    <>
      <input
        type="text"
        className="h-8 w-20 rounded-md border border-gray-300 bg-no-repeat text-xs md:w-36"
        value={value}
        onChange={e => setValue(validatePrice(e.currentTarget.value))}
        onBlur={e => onChange(validatePrice(e.currentTarget.value))}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
      ></input>
    </>
  );
};

export default InputPriceCell;
