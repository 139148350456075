import { Site } from '../CommonTypes';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase';

const fetchSites = async (company: string) => {
  if (process.env.NODE_ENV === 'development') console.log('fetching sites', company);

  const colRef = query(collection(db, `/${company}`));

  const results = await getDocs(colRef);

  return results.docs.map(doc => {
    const data = doc.data() as Omit<Site, 'siteid'>;
    return { ...data, siteid: doc.id } as Site;
  });
};

export default fetchSites;
