import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useEffect, useState } from 'react';
import { storageUrl } from '../firebase';

const CarouselApartmentPictures = ({ pictures, totalPictures }: { pictures: string[]; totalPictures: number }) => {
  // const [aspect, setAspect] = useState([0, 0]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (pictures.length > 0) {
      const img = new Image();
      img.onload = () => {
        // setAspect([img.width, img.height]);
        setLoaded(true);
      };

      img.src = pictures[0];
    }
  }, [pictures]);

  if (!loaded) {
    return <> </>;
  }
  // height:inherit;
  return (
    <CarouselProvider
      naturalSlideWidth={150}
      naturalSlideHeight={100}
      totalSlides={totalPictures}
      className="relative mt-5 h-full"
    >
      <Slider>
        {pictures.map((photo, index) => (
          <Slide innerClassName="flex flex-grow items-center justify-center overflow-hidden" key={index} index={index}>
            <img className="max-h-full max-w-full object-contain" alt="apartmentpicture" src={storageUrl(photo)} />
          </Slide>
        ))}
      </Slider>
      {pictures.length === 1 || (
        <>
          <ButtonBack className="absolute left-10 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-4xl font-bold">
            {`<`}
          </ButtonBack>
          <ButtonNext className="absolute right-5 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-4xl font-bold">
            {`>`}
          </ButtonNext>
        </>
      )}
    </CarouselProvider>
  );
};

export default CarouselApartmentPictures;
