import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, matchRoutes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-tooltip/dist/react-tooltip.css';
import NavBar from './pages/NavBar';
import Planner from './pages/Planner';
import AdminLogin from './pages/AdminLogin';
import AdminSite from './pages/AdminSite';
import { useEffect } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
    },
  },
});

const App = () => {
  const routes = [{ path: '/:company/:sitename' }];

  const route = matchRoutes(routes, location);
  const company = route ? route[0].params.company : '';

  useEffect(() => {
    if (company) {
      const cssFilePath = `/styles/${company}.css`; // Construct the CSS file path based on company
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = cssFilePath;
      document.head.appendChild(link);
    }
  }, [company]);

  const RedirectWithReload = () => {
    window.location.href = '/enklahem/Varamoviken'; // This triggers a full page reload
    return null; // Component does not render anything
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <div className="m-auto max-w-5xl">
          <NavBar />

          <Routes>
            <Route path="/" element={<RedirectWithReload />} />
            <Route path="/:company/:sitename/" element={<Planner />} />
            <Route path="/:company/admin" element={<AdminLogin />} />
            <Route path="/:company/admin/:sitename" element={<AdminSite />} />
          </Routes>
        </div>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const container = document.getElementById('root');
if (!container) {
  throw new Error('no container to render to');
}

const root = createRoot(container);
root.render(<App />);
