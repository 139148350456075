import React, { useRef, useEffect } from 'react';
import { Info } from '../CommonTypes';

const AreaInfo = ({
  d,
  info,
  toolTipPlace,
  toolTipId,
}: {
  d: string;
  info: Info;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const pathRef = useRef<SVGPathElement | null>(null);
  const iconRefPath = useRef<SVGPathElement | null>(null);

  const setDataToolTip = toolTipId
    ? {
        'data-tooltip-id': toolTipId,
        'data-tooltip-info': info?.text,
        'data-tooltip-title': info?.title,
        'data-tooltip-place': toolTipPlace,
      }
    : {};

  const isMobile = () => {
    return window.matchMedia('(max-width: 767px)').matches;
  };

  useEffect(() => {
    const handleResize = () => {
      const paths = [pathRef.current, iconRefPath.current];

      paths.forEach(path => {
        if (path) {
          const bbox = path.getBBox();
          const centerX = bbox.x + bbox.width / 2;
          const centerY = bbox.y + bbox.height / 2;

          if (isMobile()) {
            path.setAttribute(
              'transform',
              `translate(${centerX} ${centerY}) scale(1.5) translate(${-centerX} ${-centerY})`
            );
          } else {
            path.setAttribute('transform', 'scale(1)');
          }
        }
      });
    };

    handleResize(); // Call initially to set the correct state

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imgdata = info?.iconpath;

  return (
    <g {...setDataToolTip} className="infogroup">
      <path ref={pathRef} className="thecircle" style={{ fill: info?.color, opacity: info ? '0.7' : '0' }} d={d}></path>
      <path ref={iconRefPath} style={{ fill: '#FFF' }} className="innerfigure" d={imgdata} />
    </g>
  );
};

export default AreaInfo;
