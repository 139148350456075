import { useAtom } from 'jotai';
import { storageUrl } from '../firebase';
import { userData } from '../states/planner';
import { Link } from 'react-router-dom';
import useSites from '../hooks/useSites';

export const AdminPage = ({ company }: { company: string }) => {
  const [sites] = useSites(company);
  const [user] = useAtom(userData);

  return (
    <>
      <div className="planner-wrapper m-5 flex flex-wrap gap-4">
        {sites.map(site => {
          if (user && !site.admins.includes(user.id)) return false;

          return (
            <div key={site.siteid}>
              <Link to={`/${company}/admin/${site.sitename}`}>
                <button className="w-46 apartment-button px-4 py-2 pb-4 hover:opacity-90">
                  {site.sitename}
                  <img src={storageUrl(site.overviewpicture)} alt="floorplan" className="m-1 max-w-40 rounded-3xl" />
                </button>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};
