import { Cell, Row } from '@tanstack/react-table';
import { Apartment } from '../CommonTypes';
import { useAtom } from 'jotai';
import { apartmentOnTopState, hooveredApartmentId } from '../states/planner';
import StatusCell from './StatusCell';

class VirtualRow {
  id: string;
  apartmentid: string;
  cells: Cell<Apartment, unknown>[];
  apartmentOnTop?: Apartment;

  constructor(id: string, apartmentid: string, cells: Cell<Apartment, unknown>[], apartmentOnTop?: Apartment) {
    this.id = id;
    this.apartmentid = apartmentid;
    this.cells = cells;
    this.apartmentOnTop = apartmentOnTop;
  }

  getVisibleCells(): Cell<Apartment, unknown>[] {
    return this.cells;
  }

  getCellValue(cell: Cell<Apartment, unknown>) {
    if (this.apartmentOnTop) {
      const key = cell.column.id.toString();
      return this.apartmentOnTop[key as keyof Apartment];
    }

    return cell.getValue();
  }
}

const TableApartmentsRow = ({ row, isTopRow }: { row: Row<Apartment>; isTopRow?: boolean }) => {
  const [overApartmentId, setOverApartmentId] = useAtom(hooveredApartmentId);
  const [apartmentOnTop, setApartmentOnTop] = useAtom(apartmentOnTopState);

  if (!row) return null;
  if (!isTopRow && apartmentOnTop?.apartmentid === row.getValue('apartmentid')) return null;

  let vRow: VirtualRow;
  if (isTopRow) {
    if (!apartmentOnTop) return null;
    vRow = new VirtualRow('toprow', apartmentOnTop.apartmentid, row.getVisibleCells(), apartmentOnTop);
  } else {
    vRow = new VirtualRow(row.id, row.getValue('apartmentid'), row.getVisibleCells());
  }

  const classNames = [
    'tr-hooverable',
    'bg-opacity-30',
    vRow.apartmentid === overApartmentId || isTopRow ? 'bg-[#36a14d]' : '',
  ];

  const viewEvents = isTopRow
    ? {}
    : {
        onFocus: () => {
          setApartmentOnTop(null);
          setOverApartmentId(vRow.apartmentid);
        },
        onMouseOver: () => {
          setApartmentOnTop(null);
          setOverApartmentId(vRow.apartmentid);
        },
        onMouseOut: () => {
          setApartmentOnTop(null);
          setOverApartmentId('');
        },
        onBlur: () => {
          setApartmentOnTop(null);
          setOverApartmentId('');
        },
        onClick: () => {
          {
            setApartmentOnTop(null);
            setOverApartmentId(vRow.apartmentid);
          }
        },
      };

  return (
    <tr className={classNames.join(' ')} key={vRow.id} {...viewEvents}>
      {vRow.getVisibleCells().map(cell => {
        const cellId = cell.column.id.toString();
        const value = vRow.getCellValue(cell);

        let elementValue = <></>;

        if (cellId === 'status') {
          elementValue = <StatusCell status={value as string} />;
        } else if (cellId === 'price') {
          elementValue = (
            <span className="whitespace-nowrap">
              {typeof value === 'number' && value > 0 ? value.toLocaleString('sv-SE') + ' kr' : '-'}
            </span>
          );
        } else if (cellId === 'charge') {
          elementValue = (
            <span className="whitespace-nowrap">
              {typeof value === 'number' && value > 0 ? value.toLocaleString('sv-SE') + ' kr' : '-'}
            </span>
          );
        } else if (cellId === 'size') {
          elementValue = <span className="whitespace-nowrap">{(value as string) + ' m²'}</span>;
        } else {
          elementValue = <span className="whitespace-nowrap">{value as string}</span>;
        }

        return (
          <td
            {...{
              className: cellId === 'apartmentid' || cellId === 'floor' ? 'hidewhenmobile pr-3' : 'pr-3',
            }}
            key={cell.id}
          >
            {elementValue}
          </td>
        );
      })}
    </tr>
  );

  return <></>;
};

export default TableApartmentsRow;
