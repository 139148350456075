interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const TooltipInfo = ({ activeAnchor }: TArg) => {
  const info = activeAnchor?.getAttribute('data-tooltip-info') || '';
  const title = activeAnchor?.getAttribute('data-tooltip-title') || '';

  return (
    <div className="w-64 md:w-64">
      <div className="p-2 text-left text-xs font-bold md:text-base">{title}</div>
      {info && <div className="p-2 text-left text-xs md:text-sm">{info}</div>}
    </div>
  );
};

export default TooltipInfo;
